<template>
    <div>
        <particles />
        <div v-if="show">
            <div class="text_container2">
                <van-notice-bar color="#1989fa" background="#ecf9ff" font="20px">
                    <div class="texta">
                        注：建议已知症状患者测评
                    </div>
                </van-notice-bar>
                <van-collapse v-model="activeName">
                    <van-collapse-item v-for="(item,index) in dkList" :key="index" :title="item[0].tile" :name="sex == item[0].tile? '-1':index+''" :disabled='sex == item[0].tile'>
                        <div class="etext" v-for="(aitem,aindex) in  item" :key="aindex">
                            <div>
                                <div class="sta" style="margin-right: 10px">{{aitem.text}}</div>
                            </div>
                            <div>
                                <van-button size="small" type="primary" @click="gostep1(aitem.text)">开始测评</van-button>
                                &nbsp;
                                <van-button size="small" type="primary" @click="getSingleSubjectReport(aitem.text)">查看报告</van-button>
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
            <van-button class="avbut" @click="goout()" block round type="primary">返回</van-button>
        </div>
    </div>
</template>

<script>
import particles from "../components/particles";

export default {
    components: {
        particles,
    },
    data() {
        return {
            dkList: [],
            activeName: [],
            show: false,
            sex: null,
        };
    },
    watch: {
        dkList(val) {
            if (val.length != 0) {
                this.$toast.clear();
                this.show = true;
            }
        },
    },
    methods: {
        gostep1(data) {
            this.$router.push({ path: "/step1", query: { type: data } });
        },

        // 获取分科问卷id
        getHistoryReportList() {
            this.$api("getHistoryReportList").then((res) => {
                this.reportList = res.single_subject;
            });
        },
        // 获取测评列表
        getList() {
            this.$toast.loading({
                duration: 0,
                message: "请稍等...",
                forbidClick: true,
            });
            this.$api("getList", { type_text: 0, title_text: "病种证型" }).then(
                (res) => {
                       res.forEach((item,index) => {
                        this.activeName.push(index+'')
                    });
                    this.dkList = res;
                }
            );
        },
        // 查看单个问卷报告
        getSingleSubjectReport(type) {
            if (this.reportList[type].length == 0) {
                return this.$toast.fail("你还从未测评，请测评！");
            }
            let questionnaire_report_id = this.reportList[type][0].id;
            this.$router.push({
                path: "/single",
                query: {
                    questionnaire_report_id,
                },
            });
        },
        // 返回上一页
        goout() {
            this.$router.push("/report");
        },
    },
    created() {
        this.$api("getReport").then((res) => {
            this.sex = res.sex != 1 ? "男科" : "妇科";
            console.log(this.sex);
        });
    },
    mounted() {
        this.getHistoryReportList();
        this.getList();
    },
};
</script>

<style scoped >
.text_container2 {
    height: 100%;
    width: 90%;
    border-radius: 20px;
    position: relative;
    margin: 10px auto 20px;
    background: rgba(240, 240, 240, 0.8);
    overflow: auto;
    opacity: 0.8;
}
.etext {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 5px 10px;
    border-bottom: 1px black dotted;
}
.sta {
    display: flex;
    align-content: center;
    font-size: 16px;
}
.texta {
    font-size: 18px;
}
.avbut {
    width: 90%;
    margin: 0 auto;
}
</style>